<template>
    <div>
        <div style="width: 450px;margin:14% auto;text-align: center;">
             <div style="margin-bottom: 40px;" class="font-black">
                 <i style="font-size:40px;color:#38ec17;position: relative;top: 2px;margin-right: 15px;" class="iconfont icon-dggfg1"></i>
                 <span>签约成功</span>
             </div>
             <div style="margin-bottom: 50px;" class="font-grey">{{invsec}}s后自动关闭此页面</div>
             <div>
                 <!-- <div><el-button style="width: 128px;" type="primary">立即付款</el-button></div> -->
                 <!-- <div><el-button @click="back()" size="small" type="warning">返回</el-button></div> -->
             </div>
        </div>
    </div>
 </template>
 
 <script>
 
     export default {
         components: {
 
         },
         data() {
             return {
                 invsec:20,
                 timer:null,
             }
         },
         mounted() {
             this.countDown();
         },
         methods: {
             countDown() {
                 this.invsec = 30;
                 this.timer = setInterval(() => {
                     if (this.invsec == 0) {
                         // this.goCgdd();
                         window.location.href="about:blank";
                         window.close();
                         clearInterval(this.timer);
                         return;
                     }
                     this.invsec--;
                 }, 1000);
             },
             back() {
                 this.$router.go(-2);
             },
             goCgdd() {
                 this.$router.push({
                     path:"/factory/qygl",
                 });
             }
         }
     }
 </script>
 <style scoped>
     .font-grey {
         font-size: 18px;
         font-weight: 500;
         color: #808080;
         line-height: 20px;
     }
     .font-black {
         font-size: 25px;
         font-weight: 600;
         color: #000;
         line-height: 20px;
     }
 </style>